<template>
    <div
        class="d-flex align-center"
    >
        <v-text-field
            v-model="searchQuery"
            hide-details
            full-width
            dense
            clearable
            :placeholder="$trans('Start typing to Search')"
            append-outer-icon="mdi-magnify"
            @click:clear="$emit('clear')"
            @click:append-outer="$emit('input', { search: searchQuery})"
        />
    </div>
</template>

<script>
export default {
    name: 'MediaTableSearchForm',
    data() {
        return {
            searchQuery: null
        }
    }
}
</script>

<style lang=scss>

</style>
